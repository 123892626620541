exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-fr-tsx": () => import("./../../../src/pages/about-us.fr.tsx" /* webpackChunkName: "component---src-pages-about-us-fr-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-account-fr-tsx": () => import("./../../../src/pages/account.fr.tsx" /* webpackChunkName: "component---src-pages-account-fr-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-ansar-fr-mdx": () => import("./../../../src/pages/ansar.fr.mdx" /* webpackChunkName: "component---src-pages-ansar-fr-mdx" */),
  "component---src-pages-ansar-mdx": () => import("./../../../src/pages/ansar.mdx" /* webpackChunkName: "component---src-pages-ansar-mdx" */),
  "component---src-pages-atlas-relief-fr-mdx": () => import("./../../../src/pages/atlas-relief.fr.mdx" /* webpackChunkName: "component---src-pages-atlas-relief-fr-mdx" */),
  "component---src-pages-atlas-relief-mdx": () => import("./../../../src/pages/atlas-relief.mdx" /* webpackChunkName: "component---src-pages-atlas-relief-mdx" */),
  "component---src-pages-bany-ummaty-fr-mdx": () => import("./../../../src/pages/bany-ummaty.fr.mdx" /* webpackChunkName: "component---src-pages-bany-ummaty-fr-mdx" */),
  "component---src-pages-bany-ummaty-mdx": () => import("./../../../src/pages/bany-ummaty.mdx" /* webpackChunkName: "component---src-pages-bany-ummaty-mdx" */),
  "component---src-pages-causes-tsx": () => import("./../../../src/pages/causes.tsx" /* webpackChunkName: "component---src-pages-causes-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-don-fr-tsx": () => import("./../../../src/pages/don.fr.tsx" /* webpackChunkName: "component---src-pages-don-fr-tsx" */),
  "component---src-pages-donate-tsx": () => import("./../../../src/pages/donate.tsx" /* webpackChunkName: "component---src-pages-donate-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-feeding-hope-fr-mdx": () => import("./../../../src/pages/feeding-hope.fr.mdx" /* webpackChunkName: "component---src-pages-feeding-hope-fr-mdx" */),
  "component---src-pages-feeding-hope-mdx": () => import("./../../../src/pages/feeding-hope.mdx" /* webpackChunkName: "component---src-pages-feeding-hope-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-ramadan-baskets-fr-mdx": () => import("./../../../src/pages/ramadan-baskets.fr.mdx" /* webpackChunkName: "component---src-pages-ramadan-baskets-fr-mdx" */),
  "component---src-pages-ramadan-baskets-mdx": () => import("./../../../src/pages/ramadan-baskets.mdx" /* webpackChunkName: "component---src-pages-ramadan-baskets-mdx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-water-4-all-fr-mdx": () => import("./../../../src/pages/water4all.fr.mdx" /* webpackChunkName: "component---src-pages-water-4-all-fr-mdx" */),
  "component---src-pages-water-4-all-mdx": () => import("./../../../src/pages/water4all.mdx" /* webpackChunkName: "component---src-pages-water-4-all-mdx" */),
  "component---src-pages-zakat-fr-mdx": () => import("./../../../src/pages/zakat.fr.mdx" /* webpackChunkName: "component---src-pages-zakat-fr-mdx" */),
  "component---src-pages-zakat-mdx": () => import("./../../../src/pages/zakat.mdx" /* webpackChunkName: "component---src-pages-zakat-mdx" */)
}

